
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200..900&display=swap');

html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3',sans-serif;
}
.app{
  display: flex;
  position: relative;
}
::-webkit-scrollbar{
  width :10px;
}

/* Track */
::-webkit-scrollbar-track{
  background: #e0e0e0
}

/* Handle */
::-webkit-scrollbar-thumb{
  background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover{
  background: #555;
}